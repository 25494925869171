export default async function init() {
  const tabs = document.querySelectorAll('[data-js-module="tabs"]');
  if (tabs) {
    const { default: Tabs } = await import(/* webpackChunkName: "tabs" */ './tabs');

    tabs.forEach((element) => {
      return new Tabs(element);
    });
  }
}

init();
