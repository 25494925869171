export default async function init() {
  const tombstonesElememts = document.querySelectorAll('[data-js-module="tombstones"]');

  const initTombstones = async () => {
    const { default: Tombstones } = await import(/* webpackChunkName: "tombstones" */ './tombstones.js');
    tombstonesElememts.forEach((tombstonesElememt) => new Tombstones(tombstonesElememt));
  };

  if (tombstonesElememts.length) initTombstones();
}

init();
