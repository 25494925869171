const initTeaserRowTracking = () => {
  const teaserLinks = document.querySelectorAll('.teaser-row__teaser a');

  teaserLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'teaserRowLinkClick',
        targetUrl: link.href,
        teaserTitle: link.innerText.trim() || 'unknown'
      });
    });
  });
};

export default initTeaserRowTracking;
