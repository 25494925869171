export default async function init() {
  const notifications = document.querySelectorAll('[data-js-module="notification"]');

  const initToolTip = async () => {
    const { default: Notification } = await import(/* webpackChunkName: "notification" */ './notification.js');
    notifications.forEach((notification) => new Notification(notification));
  };

  if (notifications.length) initToolTip();
}

init();
