export default async function init() {
  const faqContainers = document.querySelectorAll('[data-js-module="faq-container"]');

  const initFaqContainer = async () => {
    const { default: FaqContainer } = await import(/* webpackChunkName: "faq-container" */ './faq-container.js');
    faqContainers.forEach((faq) => new FaqContainer(faq));
  };

  if (faqContainers.length) initFaqContainer();
}

init();
