export default async function init() {
  const fixingRates = document.querySelectorAll('[data-js-module="fixing-rates"]');
  const initApp = async () => {
    const { default: FixingRatesApp } = await import(/* webpackChunkName: "fixing-rates" */ './fixing-rates.jsx');
    fixingRates.forEach((element) => new FixingRatesApp(element));
  };

  if (fixingRates.length) initApp();
}

init();
