export default async function init() {
  const investmentAdvices = document.querySelectorAll('[data-js-module="investment-advice"]');
  const initApp = async () => {
    const { default: InvestmentAdviceApp } = await import(/* webpackChunkName: "investment-advice" */ './investment-advice.jsx');
    investmentAdvices.forEach((element) => new InvestmentAdviceApp(element));
  };

  if (investmentAdvices.length) initApp();
}

init();
