const initFooterTracking = () => {
  // Tracking für Hero-Links
  const heroLinks = document.querySelectorAll('.footer__hero-content');
  heroLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'footer_hero',
        targetUrl: link.href,
        teaserTitle: link.innerText.trim() || 'unknown'
      });
    });
  });

  // Tracking für weitere Footer-Links
  const footerLinks = document.querySelectorAll('.footer__link'); // Aktualisiere den Selector für deine Footer-Links
  footerLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'footer_link',
        targetUrl: link.href,
        linkText: link.innerText.trim() || 'unknown'
      });
    });
  });
};

export default initFooterTracking;
