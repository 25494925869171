import shouldAnimate from './shouldAnimate';

export default async () => {
  // if not in percy test task or if not reduced-motion setting
  if (shouldAnimate()) {
    // TODO add more animations where needed
    const { default: animation } = await import('../../components/teaser-highlight/animation');
    animation();
  }
};
