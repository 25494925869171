export default async function init() {
  const inxmails = document.querySelectorAll('[data-js-module="inxmail"]');

  const initInxmail = async () => {
    const { default: Inxmail } = await import(/* webpackChunkName: "inxmail" */ './inxmail.js');
    inxmails.forEach((inxmail) => new Inxmail(inxmail));
  };

  if (inxmails.length) initInxmail();
}

init();
