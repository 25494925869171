export default async function init() {
  const locations = document.querySelectorAll('[data-js-module="location"]');
  const initLocation = async () => {
    const { default: LocationApp } = await import(/* webpackChunkName: "location" */ './location.jsx');
    locations.forEach((element) => new LocationApp(element));
  };

  if (locations.length) initLocation();
}

init();
