/**
 * Should animation be displayer
 * @returns {boolean} - if animation should be shown
 */
export default () => {
  // if  user has set preference to minimize the amount of animation/motion
  const isReducedMotionMediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

  return !process.env.PERCY_TOKEN && !isReducedMotionMediaQuery.matches;
};
