export default async function init() {
  const menubars = document.querySelectorAll('[data-js-module="menubar"]');

  const initMenubar = async () => {
    const { default: Menubar } = await import(/* webpackChunkName: "menubar" */ './menubar.js');
    menubars.forEach((menubar) => new Menubar(menubar));
  };

  if (menubars.length) initMenubar();
}

init();
