export default async function init() {
  const accordions = document.querySelectorAll('[data-js-module="accordion"]');

  const initAccordion = async () => {
    const { default: Accordion } = await import(/* webpackChunkName: "accordion" */ './accordion.js');
    accordions.forEach((accordion) => new Accordion(accordion));
  };

  if (accordions.length) initAccordion();
}

init();
