export default async function init() {
  const tooltips = document.querySelectorAll('[data-js-module="tooltip"]');

  const initToolTip = async () => {
    const { default: Tooltip } = await import(/* webpackChunkName: "tooltip" */ './tooltip.js');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  };

  if (tooltips.length) initToolTip();
}

init();
