export default async function init() {
  const modals = document.querySelectorAll('[data-js-module="modal"]');

  const initModal = async () => {
    const { default: Modal } = await import(/* webpackChunkName: "modal" */ './modal.js');
    modals.forEach((modal) => new Modal(modal));
  };

  if (modals.length) initModal();
}

init();

// export default async function init() {
//   const modals = document.querySelectorAll('[data-js-module="modal"]');

//   const { default: Modal } = await import(/* webpackChunkName: "modal" */ './modal');

//   modals.forEach((element) => new Modal(element));
// }

// init();
