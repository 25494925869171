export default async function init() {
  const videos = document.querySelectorAll('[data-js-module="video"]');

  const initVideo = async () => {
    const { default: Video } = await import(/* webpackChunkName: "video" */ './video.js');
    videos.forEach((video) => new Video(video));
  };

  if (videos.length) initVideo();
}

init();
