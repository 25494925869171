export default async function init() {
  const stagesHome = document.querySelectorAll('[data-js-module="stage-home"]');
  if (stagesHome) {
    const { default: StageHome } = await import(/* webpackChunkName: "stage-home" */ './stage-home');

    stagesHome.forEach((element) => {
      return new StageHome(element);
    });
  }
}

init();
