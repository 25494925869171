export default async function init() {
  const navigations = document.querySelectorAll('[data-js-module="navigation"]');

  const initNavigation = async () => {
    const { default: Navigation } = await import(/* webpackChunkName: "navigation" */ './navigation.js');
    navigations.forEach((navigation) => new Navigation(navigation));
  };

  if (navigations.length) initNavigation();
}

init();
