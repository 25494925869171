export default async function init() {
  const topBtns = document.querySelectorAll('[data-js-module="top-btn"]');

  const initTopBtn = async () => {
    const { default: TopBtn } = await import(/* webpackChunkName: "top-btn" */ './top-btn.js');
    topBtns.forEach((topBtn) => new TopBtn(topBtn));
  };

  if (topBtns.length) initTopBtn();
}

init();
