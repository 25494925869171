export default async function init() {
  const investorProfiles = document.querySelectorAll('[data-js-module="investor-profile"]');
  const initApp = async () => {
    const { default: InvestorProfileApp } = await import(/* webpackChunkName: "investor-profile" */ './investor-profile.jsx');
    investorProfiles.forEach((element) => new InvestorProfileApp(element));
  };

  if (investorProfiles.length) initApp();
}

init();
