import shouldAnimate from './shouldAnimate';

export default (element, delay = 0) => {
  if (!element) return;

  // add buffer for sticky navigation to top position
  const scrollTop = element.offsetTop - 120;

  if (shouldAnimate) {
    // scroll to element after delay
    setTimeout(() => {
      window.scrollTo({
        top: scrollTop,
        left: 0,
        behavior: 'smooth'
      });
    }, delay);
  } else {
    window.scrollTo({
      top: scrollTop,
      left: 0,
      behavior: 'instant'
    });
  }
};
