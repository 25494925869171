// So this file does, that this component works.

const requireAll = (r) => {
  r.keys().forEach(r);
};

if (process.env.NODE_ENV === 'development') {
  // add all icons to icon sprite
  requireAll(require.context('../icons/', true, /\.svg$/));
} else {
  // add only icons used in components to icon sprite
  requireAll(require.context('../icons/in-use/', true, /\.svg$/));
}
