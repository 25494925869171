export default async function init() {
  const charts = document.querySelectorAll('[data-js-module="chart"]');
  const initChart = async () => {
    const { default: Chart } = await import(/* webpackChunkName: "chart" */ './chart.js');
    charts.forEach((chart) => new Chart(chart));
  };

  if (charts.length) initChart();
}

init();
