export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="contact"]');

  const initContact = async () => {
    const { default: Contact } = await import(/* webpackChunkName: "contact" */ './contact');
    elements.forEach((contactElememt) => new Contact(contactElememt));
  };

  if (elements.length) initContact();
}

init();
