export default async function init() {
  const searchInputs = document.querySelectorAll('[data-js-module="search"]');
  const searchAutoInputs = document.querySelectorAll('[data-js-module="search-auto"]');
  const initApp = async () => {
    if (searchInputs) {
      const { default: Search } = await import(/* webpackChunkName: "search" */ './search.js');
      searchInputs.forEach((element) => new Search(element));
    }

    if (searchAutoInputs) {
      const { default: SearchAuto } = await import(/* webpackChunkName: "search" */ './search.jsx');
      searchAutoInputs.forEach((element) => new SearchAuto(element));
    }
  };

  if (searchInputs.length || searchAutoInputs.length) initApp();
}

init();
